import React, {Fragment} from 'react';
import Header from "../../components/header/HeaderOne";
import SideHeader from "../../components/SideHeader";
import CasesTemplate from "../../templates/cases";
import FooterTwo from "../../components/footer/FooterTwo";
import casesData from '../../data/cases';

const CasesPage = () => {
    const count = 3;
    const cases = casesData.slice(0, count);

    const allItems = count >= casesData.length;

    return (
        <Fragment>
            <Header classes={'position-static'}/>
            <SideHeader mobile={true}/>
            <CasesTemplate cases={cases} fullWidth={true}/>
            <FooterTwo allItems={allItems} />
        </Fragment>
    );
};

export default CasesPage;