//index.js @src/components/project

import React from 'react';
import {Link} from 'react-router-dom';
import projectDetailsData from '../../data/cases';

const Project = ({title, thumb, category, variant, classes, id}) => {
    const service = projectDetailsData[id-1].link;

    return (
        <div className={`portfolio-item ${classes ? classes : ' '}`}>
            <Link to={`${process.env.PUBLIC_URL + "/" + service}`} className={'d-block'} id={id}>
                {variant === 'column' ? (
                    <div className="portfolio-image">
                        <img src={require('../../assets/images/' + thumb)} alt={title}/>
                    </div>

                ) : (
                    <div className="portfolio-image"
                         style={{backgroundImage: `url(${require('../../assets/images/' + thumb)})`}}/>
                )}
                <div className="portfolio-content">
                    <h4 className="title">{title}</h4>
                    <div className="category"><span>{category}</span></div>
                </div>
            </Link>
        </div>
    );
};

export default Project;
