import React, {Fragment} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Switcher from "./components/Switcher";

/*
* @ Component Imported
* */

// import BlogThreeColumnPage from "./pages/blog/ThreeColumn";
// import BlogTwoColumnLeftSidebarPage from "./pages/blog/TwoColumnLeftSidebar";
// import BlogTwoColumnRightSidebarPage from "./pages/blog/TwoColumnRightSidebar";
// import BlogDetailsRightSidebarPage from "./pages/blog/BlogDetailsRightSidebar";
// import BlogDetailsLeftSidebarPage from "./pages/blog/BlogDetailsLeftSidebar";
// import ServicePage from "./pages/service";
// import TeamPage from "./pages/team";
// import DemoPage from "./pages/demo";
// import HomeThreeColumn from "./pages/home/HomeThreeColumn";
// import HomeFourColumn from "./pages/home/HomeFourColumn";
// import HomeFiveColumn from './pages/home/HomeFiveColumn';
// import HomeBlog from "./pages/home/HomeBlog";
// import HomePersonalPortfolio from "./pages/home/HomePersonalPortfolio";
// import HomeFreelancerPortfolio from "./pages/home/HomeFreelancerPortfolio";

import AboutPage from "./pages/about";
import ContactPage from "./pages/contact";
import CasesPage from './pages/cases';
import CaseDetailPage from './pages/cases/details';
import ErrorPage from "./pages/404Error";
import HomeDefault from "./pages/home/HomeDefault";
import PortfolioDetailsPage from "./pages/portfolio/details";
import ParceirosThreeColumn from './pages/parceiros/ParceirosThreeColumn';

const App = () => {
    return (
        <Fragment>
            <Switcher/>
            <Router>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL}/contato`}
                           component={ContactPage}/>
                    <Route path={`${process.env.PUBLIC_URL}/parceiros`}
                           component={ParceirosThreeColumn}/>
                    <Route path={`${process.env.PUBLIC_URL}/portfolio/hardware`}
                            render={() => <PortfolioDetailsPage id={1} />}
                     />
                     <Route path={`${process.env.PUBLIC_URL}/portfolio/firmware`}
                            render={() => <PortfolioDetailsPage id={2} />}
                     />
                     <Route path={`${process.env.PUBLIC_URL}/portfolio/software`}
                            render={() => <PortfolioDetailsPage id={3} />}
                     />
                     <Route path={`${process.env.PUBLIC_URL}/portfolio/app`}
                            render={() => <PortfolioDetailsPage id={4} />}
                     />
                     <Route path={`${process.env.PUBLIC_URL}/portfolio/design`}
                            render={() => <PortfolioDetailsPage id={5} />}
                     />
                     <Route path={`${process.env.PUBLIC_URL}/portfolio/prototipagem`}
                           render={() => <PortfolioDetailsPage id={6} />}
                     />
                     <Route path={`${process.env.PUBLIC_URL}/portfolio/producao`}
                           render={() => <PortfolioDetailsPage id={7} />}
                     />
                     <Route path={`${process.env.PUBLIC_URL}/portfolio/suporte`}
                           render={() => <PortfolioDetailsPage id={8} />}
                     />
                     <Route path={`${process.env.PUBLIC_URL}/portfolio/consultoria`}
                           render={() => <PortfolioDetailsPage id={9} />}
                     />

                     <Route path={`${process.env.PUBLIC_URL}/projetos`}
                           component={CasesPage}
                     />
                     
                     <Route path={`${process.env.PUBLIC_URL}/case-medical`}
                           render={() => <CaseDetailPage id={1} />}
                     />
                     
                     <Route path={`${process.env.PUBLIC_URL}/case-monitoring`}
                           render={() => <CaseDetailPage id={2} />}
                     />
                     
                     <Route path={`${process.env.PUBLIC_URL}/case-sensor`}
                           render={() => <CaseDetailPage id={3} />}
                     />

                    <Route path={`${process.env.PUBLIC_URL}/sobre`}
                           component={AboutPage}
                     />
                    
                    <Route path={`${process.env.PUBLIC_URL}/home`}
                           component={HomeDefault}
                     />
                    
                    <Route exact path={`${process.env.PUBLIC_URL}/`}
                           component={HomeDefault}
                           /*component={DemoPage}*/
                    />
                    
                    <Route exact component={ErrorPage} />
                    {/* <Route path={`${process.env.PUBLIC_URL + "/home-freelancer-portfolio"}`}
                           component={HomeFreelancerPortfolio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-personal-portfolio"}`}
                           component={HomePersonalPortfolio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-blog"}`}
                           component={HomeBlog}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-five-column"}`}
                           component={HomeFiveColumn}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-four-column"}`}
                           component={HomeFourColumn}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-three-column"}`}
                           component={HomeThreeColumn}/>     
                    <Route path={`${process.env.PUBLIC_URL + "/team"}`}
                           component={TeamPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/cases"}`}
                           component={ServicePage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-details-left-sidebar"}`}
                           component={BlogDetailsLeftSidebarPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-details"}`}
                           component={BlogDetailsRightSidebarPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-two-column-left-sidebar"}`}
                           component={BlogTwoColumnLeftSidebarPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-two-column-right-sidebar"}`}
                           component={BlogTwoColumnRightSidebarPage}/> */}              
                </Switch>
            </Router>
        </Fragment>
    );
};

export default App;