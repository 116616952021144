//index.js @src/templates/cases

import React, {Fragment} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import PageContainer from '../../container/CustomPage';
import Project from '../../components/project';

const CasesTemplate = ({cases, fullWidth}) => {

    return (
        <Fragment>
            <PageContainer classes={'bg-grey'} fullWidth={fullWidth}>
                <Container fluid={!!fullWidth} className={fullWidth ? 'p-0' : null}>
                    <Row className={'row-7 portfolio-column-three'}>
                        {cases.map(caseItem => (
                            <Col key={caseItem.id} md={6} lg={4} className={'mb-15'}>
                                <Project
                                    title={caseItem.title}
                                    link={caseItem.link}
                                    thumb={caseItem.thumb}
                                    category={caseItem.category}
                                    variant={'column'}
                                    id={caseItem.id}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </PageContainer>

        </Fragment>
    );
};

export default CasesTemplate;