import React, {Fragment} from 'react';

// File imported
import Header from "../../../components/header/HeaderOne";
import SideHeader from "../../../components/SideHeader";
import BannerFreelancerPortfolio from "../../../components/HeroBanner/FreelancerPortfolio";
import SobreProjeto from "../../../container/SobreProjeto";
import Footer from "../../../components/footer/FooterThree";

const CaseDetailPage = (props) => {
    const id = props.id;

    return (
        <Fragment>
            <Header classes={'position-static'}/>
            <SideHeader mobile={true}/>
            <BannerFreelancerPortfolio id={id}/>
            <SobreProjeto id={id}/>
            <Footer position={'static'}/>
        </Fragment>
    );
};

export default CaseDetailPage;
