import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import PageContainer from "../../../container/CustomPage";
import PortfolioDetailsThumb from "./thumbnail";
import PortfolioDetailsContent from "./content";
import portfolioDetailsData from '../../../data/portfolio/details'

const TemplatePortfolioDetails = (props) => {
    const id = props.id;
    console.log('ID e tipo: ' + id + '\n' + typeof id);
    return (
        <PageContainer classes={'bg-grey'}>
            <Container>
                <Row>
                    <Col lg={7}>
                        <PortfolioDetailsThumb images={portfolioDetailsData[id-1].previewImages}/>
                    </Col>

                    <Col lg={5}>
                        <PortfolioDetailsContent
                            title={portfolioDetailsData[id-1].title}
                            category={portfolioDetailsData[id-1].category}
                            description={portfolioDetailsData[id-1].description}
                            meta={portfolioDetailsData[id-1].meta}
                        />
                    </Col>
                </Row>
            </Container>
        </PageContainer>
    );
};

export default TemplatePortfolioDetails;