//index.js @ src/components/portfolio

import React from 'react';
import {Link} from 'react-router-dom'
import portfolioDetailsData from '../../data/portfolio/details';

const Portfolio = ({thumb, classes, title, category, variant, id}) => {
    const service = portfolioDetailsData[id-1].link;

    return (
        <div className={`portfolio-item ${classes ? classes : ' '}`}>
            <Link to={`${process.env.PUBLIC_URL + "/portfolio/" + service}`} className={'d-block'} id={id}>
                {variant === 'column' ? (
                    <div className="portfolio-image">
                        <img src={require('../../assets/images/' + thumb)} alt={title}/>
                    </div>

                ) : (
                    <div className="portfolio-image"
                         style={{backgroundImage: `url(${require('../../assets/images/' + thumb)})`}}/>
                )}
                <div className="portfolio-content">
                    <h1 className="title">{title}</h1>
                    <div className="category"><h1>{category}</h1></div>
                </div>
            </Link>
        </div>
    );
};

export default Portfolio;
