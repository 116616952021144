import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Content from "../../components/content";
import casesData from '../../data/cases';
import FotosProjeto from "../../container/FotosProjeto";


const SobreProjeto = ({type, id}) => {
    const description = casesData[id - 1].description;
    return (
        <div className={type !== 'page' ? 'section-small-padding section' : null}>
            <Container>
                <Row className={'align-items-center'}>
                <Col lg={6}>
                        <Content classes={'about-content'}>
                            {type !== 'page' ? (<h3 className="block-title">SOBRE O PROJETO</h3>) : null}
                            <p>{description}</p>
                        </Content>
                    </Col>
                    
                    <Col lg={6}>
                        <FotosProjeto id={id}/>
                    </Col>
                </Row>
                <Row style={{flex: 1, justifyContent: 'center', paddingTop: 10}}>
                    <span style={{fontWeight: 'bold', marginRight: 5}}>Categorias: </span>
                    {casesData[id - 1].keywords.map((keyword,index) => (
                        <a key={index} href="/" style={{marginRight: 5}}>{keyword}</a>
                    ))}
                </Row>
            </Container>
            <ul className="project-info">
                <li>
                    
                </li>
            </ul>
        </div>
    );
};

export default SobreProjeto;