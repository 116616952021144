import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Form from "../../components/form";
import QuickContact from "../../components/QuickContact";
import Map from "../../components/map";

const Contact = () => {
    const endereço = 'Cietec, Sala 246 - Avenida Professor Lineu Prestes, 2242, Cidade Universitária - São Paulo, SP - CEP 05508-000';
    const email = 'contato@pullup.com.br';
    const telefone = '+55 11 3039-8311';
    const textoMapa = 'A Pullup está incubada no Cietec, o Centro de Inovação, Empreendedorismo e Tecnologia, situado dentro da Cidade Universitária da USP, em São Paulo.';
    return (
        <div className="bg-grey section ">
            <div
                className="section-wrap section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50">
                <Container>
                    <Row>
                        <Col lg={6} className={'mb-sm-45 mb-xs-45'}>
                            <Row>
                                <Col lg={10} className={'mb-45'}>
                                    <div className="contact-form">
                                        <h3>FALE CONOSCO</h3>
                                        <Form/>
                                    </div>
                                </Col>

                                <Col lg={10}>
                                    <QuickContact
                                        phone={telefone}
                                        email={email}
                                        location={endereço}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={6}>
                            <Map
                                text={textoMapa}
                                lat={-23.56438198997727}
                                long={-46.73510342887193}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Contact;