//index.js @ src/components/parceiros

import React from 'react';

const Parceiros = ({title, thumb, site, excerpt}) => {
    return (
        <div className="blog-item">
            <div className="blog-image">
                <a href={`${site}`}>
                    <img src={require('../../assets/images/' + thumb)} alt={title} style={{marginRight: 50, marginLeft: 50, maxWidth: 300, maxHeight: 300}}/>
                </a>
            </div>
{/* 
            <div className="blog-content">
                <h4 className="blog-title">
                    {title}
                </h4>

                <div className="blog-desc">
                    <p>{excerpt}</p>
                </div>

            </div> */}

        </div>
    );
};

export default Parceiros;