import React, { useState } from 'react';
import Input from "../input";
import api from '../../services/api';
import { payload } from '../../services/payload';

const Form = () => {
    const [status, setStatus] = useState("Enviar");
    const handleSubmit = async (e) => {
      e.preventDefault();
      setStatus("Enviando...");
      const { name, email, phone, message } = e.target.elements;
      let details = {
        name: name.value,
        email: email.value,
        phone: phone.value,
        message: message.value,
      };

      e.target.reset();

        const response = await api.post(payload.endpoints.send_feedback, {
            data: {
              recipient_email: 'rafael.bmosna@gmail.com',
              message: details.message,
              user_name: details.name,
              user_email: details.email,
              cell_phonenumber: details.phone,
              user_county: 'Brasil?',
              subject: `[Contato Site Pullup] Mensagem de ${details.name}`,
              company: 'pullup',
            }
          });

      if (response.statusText==='OK') {
          setStatus("Mensagem Enviada!");
          setTimeout(() => {
              setStatus("Enviar");
          }, 2500);
      } else {
          setStatus("Falha no Envio!");
          setTimeout(() => {
              setStatus("Enviar")
          }, 2500);
      }
    };

    return (
        <form id="contact-form" action="/" method="post" onSubmit={ handleSubmit }>
            <Input type={'text'} id={'name'} name={'name'} placeholder={'Nome'} required={true}/>
            <Input type={'email'} id={'email'} name={'email'} placeholder={'E-mail'} required={true}/>
            <Input type={'telephone'} id={'phone'} name={'phone'} placeholder={'Telefone'} required={false}/>
            <Input type={'textarea'} id={'message'} name={'message'} placeholder={'Mensagem'} required={true}/>
            <button type={'submit'}>
                {status}
            </button>
        </form>
    );
};

export default Form;