import React from 'react';
import HeroBannerContainer from "../../../container/HeroBanner";
import projectDetailsData from '../../../data/cases';

const BannerFreelancerPortfolio = ({id}) => {
    const nomeProjeto = projectDetailsData[id - 1].title;
    const service = projectDetailsData[id - 1].category;
    const background = projectDetailsData[id - 1].background;
    const image = require('../../../assets/images/' + background);
    return (
        <HeroBannerContainer bg={image} overlay={true}>
            <div className={'text-center'}>
                <div className="slider-banner-info text-center">
                    <h2 className="slider-banner-title"><b>{nomeProjeto}</b></h2>
                    <h5 className="slider-banner-text">{service}</h5>
                </div>
            </div>
        </HeroBannerContainer>
    );
};

export default BannerFreelancerPortfolio;