import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import PageContainer from "../../container/CustomPage";
import Parceiros from "../../components/parceiros";
import parceirosData from '../../data/parceiros';

const TemplateParceirosSemSidebar = ({blogColumnClass}) => {
    const parceirosPerPage = 3;
    const parceiros = parceirosData.slice(0, parceirosPerPage);

    return (
        <PageContainer classes={'bg-grey'}>
            <Container>
                <Row className="blog-content-wrap">
                    {parceiros.map(parceiro => (
                        <Col key={parceiro.id} className={blogColumnClass}>
                            <Parceiros
                                title={parceiro.title}
                                thumb={parceiro.thumb}
                                excerpt={parceiro.excerpt}
                                site={parceiro.site}
                            />
                        </Col>
                    ))}
                </Row>

            </Container>

        </PageContainer>
    );
};

export default TemplateParceirosSemSidebar;