import React, {useState, useEffect} from 'react';

const PortfolioDetailsContent = ({title, category, description, meta}) => {
    const [width, setWidth] = useState(window.innerWidth);
        function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = width <= 768 ? true : false;

    const emailText = 
        `mailto:?subject=Confira%20a%20Pullup&body=Ol%C3%A1!%20Encontrei%20essa%20empresa%20que%20realiza%20servi%C3%A7os%20em%20${title}.%0AVeja%20que%20interessante%3A%20https%3A%2F%2Fwww.pullup.com.br`;
    
    const whatsappText = isMobile
        ? `https://api.whatsapp.com/send?phone&text=Ol%C3%A1!%20Encontrei%20essa%20empresa%20que%20realiza%20servi%C3%A7os%20em%20${title}.%0AVeja%20que%20interessante%3A%20https%3A%2F%2Fwww.pullup.com.br&app_absent=0`
        : `https://web.whatsapp.com/send?phone&text=Ol%C3%A1!%20Encontrei%20essa%20empresa%20que%20realiza%20servi%C3%A7os%20em%20${title}.%0AVeja%20que%20interessante%3A%20https%3A%2F%2Fwww.pullup.com.br&app_absent=0`;

    return (
        <div className="portfolio-details-content">

            <a href="/" className="category">{category}</a>

            <h1 className="title">{title}</h1>

            <p>{description}</p>

            <ul className="project-info">
                {meta.etapas.map((etapa, index) => (
                    <li><span>{etapa.name}</span> {etapa.description}</li>
                ))}
                {/* <li><span>Client:</span> {meta.client}</li>
                <li><span>Date:</span> {meta.date}</li>
                <li><span>Website:</span>
                    <a href={meta.website}
                       target="_blank"
                       rel="noopener noreferrer">{meta.website}
                    </a>
                </li> */}
                <li>
                    <span>Categorias:</span>
                    {meta.services.map((service,index) => {
                        const text = index === 0 ? `${service}` : `\xa0${service}`;
                        return <a key={index} href="/">{text}</a>
                    })}
                </li>
            </ul>

            <div className="project-share">
                <span>Compartilhar via:</span>
                <a href={whatsappText}><i className="fa fa-whatsapp"/></a>
                <a href={emailText}><i className="fa fa-envelope"/></a>
            </div>

        </div>
    );
};

export default PortfolioDetailsContent;