import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import SectionTitle from "../../components/SectionTitle";
import CasePhoto from "../../components/CasePhoto";
import casesData from '../../data/cases';

const FotosProjeto = ({id}) => {
    const fotos = casesData[id - 1].previewImages;

    return (
        <div className="section pt-120 pt-lg-80 pt-md-80 pt-sm-80 pt-xs-50 pb-120 pb-lg-80 pb-md-80 pb-sm-80 pb-xs-50">
            <Container>
                <Row className={'portfolio-column-four mbn-30'}>
                    {fotos.map(foto => (
                        <Col key={foto.id} lg={6} md={6} className={'mb-30'}>
                            <CasePhoto
                                title={foto.title}
                                thumb={foto.thumb}
                                category={foto.category}
                                variant={'column'}
                                id={id}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default FotosProjeto;