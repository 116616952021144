import React from 'react';

const Map = ({text, lat, long}) => {
    return (
        <div className="row">
            <div className="col-lg-10 col-12 mb-45">
                <div className="contact-availability">
                    <h3>ONDE ESTAMOS</h3>
                    <p>{text}</p>
                </div>
            </div>
            <div className="col-lg-10 col-12">
                <div id="contact-map">
                    <iframe
                        src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.109243090899!2d-46.73735648508469!3d-23.564519684681358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce566cdaac67a1%3A0x3b1b7eed9233211f!2sPullup%20Solu%C3%A7%C3%B5es%20em%20Sistemas%20Eletr%C3%B4nicos!5e0!3m2!1spt-BR!2sbr!4v1606504083330!5m2!1spt-BR!2sbr"}
                        // src={`https://maps.google.com/maps?q=${lat},${long}&hl=es&z=10&output=embed`}
                        title={'Contact Map'}
                        style={{width: '100%', height: '100%', border: 0}}
                    />
                        {/* <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.109243090899!2d-46.73735648508469!3d-23.564519684681358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce566cdaac67a1%3A0x3b1b7eed9233211f!2sPullup%20Solu%C3%A7%C3%B5es%20em%20Sistemas%20Eletr%C3%B4nicos!5e0!3m2!1spt-BR!2sbr!4v1606504083330!5m2!1spt-BR!2sbr"
                            width="100%"
                            height="100%"
                            frameborder="0"
                            title={'Contact Map'}
                            style={{border: 0}}
                            allowfullscreen=""
                            aria-hidden="false"
                            tabindex="0"
                        /> */}
                </div>
            </div>
        </div>
    );
};

export default Map;